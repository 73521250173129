@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Caveat&family=Roboto:wght@300;400&display=swap');

:root {
  --pink: rgb(255, 213, 235);
  --cyan: #e7f7ff;
  --yellow: rgb(255, 255, 219);
  --dpink: rgb(255, 215, 187);
  --orange: rgb(255, 0, 43);
  --lorang: rgb(255, 241, 222);
  --blue: navy;
  --lemon: rgb(213, 255, 213);
  --green: rgb(113, 255, 113);
  --purple: rgb(70, 0, 128);

  --radi1: 8px;
  --radi: 1.5rem;

}

body {
  background-color: white;
}
html {
  scroll-behavior: smooth;
}

/* ---Colors--- */
.bg-p {
  background-color: var(--pink);
}
.bg-cyan {
  background-color: var(--cyan);
}
.bg-yellow{
  background-color: var(--yellow);
}
.bg-blue{
  background-color: var(--blue);
}
.bg-orange {
  background-color: var(--orange);

}
.bg-lorange {
  background-color: var(--lorang);
}
.bg-dpink {
  background-color: var(--dpink);
}
.bg-green {
  background-color: var(--green);

}

.bg-purple {
  background-color: rgba(0, 255, 106, 0.152);
}

.bg-lem{
  background-color:rgb(212, 255, 212) ;
}

.bg-lemon{
  background-color: var(--lemon);
}
/* Color-ends */


*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "roboto", sans-serif;
}

.navbar {
  display: flex;
  padding:  1.5rem 2rem;
  align-items: center;
  justify-content: center;
  
}

.container{
  display: flex;
  width: 85%;
  margin: 0 auto;
 
}

.rad {
 
  border-radius: 2rem;
}



.col{
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;
}

.w1{
  width: 100%;

}
.w2{
  width: 50%;
  
}
.w3{
  width: 30%;
  
}
.w4{
  width: 25%;
  
}


.l-gap {
  margin-left: -9rem;
}

.nav-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25%;
  height: 3rem;
  overflow-y: hidden;
  margin: 0 auto;
 
}
.h-logo {
  width: 100%;
}

.header {
  display: flex;
  max-width: 100vw;
  
  
}

.purp-b {
  position: relative;
 
  height: 32rem;
  width: 85%;

  
}

.hero {
  position: absolute;
  margin: 0 auto;
  color: whitesmoke;
  position:  relative;

  height:  25rem;
  left: 0;
  top: 6rem;
  z-index: 1;
  width: 95%;
  margin:  0 auto;
  align-items: center;
  justify-content: center;

}

.asterisk {
  width: 2.5rem;
  margin: 0 8px;
}



.bg-image-div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 32rem;
  border-radius: 2rem;

  overflow: hidden;
  z-index: 0;
  
}
.header-bg{
  width: 80rem;
  position: relative;
  top: -2rem;
  left: -2rem;
  
}
.pips {
  width: 65rem;
  position: absolute;
  bottom:  -0.5rem;
  left: 1rem;
 
  animation: updown 12s ease-in-out infinite;
}

@keyframes updown {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
  
}
a {
  text-decoration: none;
}
.pip-icon {
  font-size: 2rem;
  color: rgba(192, 165, 255, 0.83);
  position: absolute;
  z-index: 2;
  animation: rotate 10s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
  
}
.a{
  top: 70%;
  left: 1rem;
}
.b{
  top: 85%;
  left: 90%;

}
.x{
  top: 90%;
  left: 70%;
}
.y{
  top: 90%;
  left: 40%;
}
.header1 {
  font-size: 3.5rem;
  margin: 0;
  padding: 0;
  font-weight: 500;
}

.fa-asterisk {
  font-size: 2rem;
  font-weight: 200;
}

.h-text {
  font-size: 14px;
  width: 40%;
  margin: 0 auto;
  position: relative;
  
  margin-top: 1rem;
}
.h-text2 {
  font-size: 14px;
  width: 45%;
  margin: 0 auto;
  position: relative;
  text-align: center;
  margin-top: 1rem;
}
.btn-started {
  border-radius: 50px;
  background-color: whitesmoke;
  padding: 1rem ;
  width: 10rem;
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  color: blue;
  font-size: 18px;
  margin-top: 2rem;
  cursor: pointer;
  transition: background-color 0.5s ease, border-color 0.5s ease, color 0.5s ease;
  border: 2px solid whitesmoke;
  
  
}
.num {
  font-family: 'Courier New', Courier, monospace;
  font-weight: bold;
}


.btn-started:hover {
  color: white;
  background-color: transparent;
}



.section {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  max-width: 100vw;
}

.section-header {
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
 width: 32%;
  text-align: left;

}

.stily-text {
  font-size: 15px;
  color: purple;
  font-family: 'Caveat', cursive;
}

.header2 {
  font-size: 1.5rem;
  margin: 0;
  padding: 0;
  font-weight: 500;
}


.box-div {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.box-cover{
  display: flex;
  flex-direction: column;
  background-color: whitesmoke;
  padding: 1rem 0.5rem; 
  margin-top: 1rem;
  border-radius: var(--radi);
  width: 30%;
}

.box {
  width: 95%;
  margin: 0 auto;
  border-radius: 1rem;
  padding: 1rem 0;
  margin-top: 1rem;
  height: 10rem;
  padding: 0.5rem;
  
}

.box-2 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-header{
  font-size: 1rem;
  text-align: left;
  margin-left: 0.5rem;
  font-weight: 500;
}

.card-text {
  font-size: 14px;
  text-align: left;
  color: rgb(34, 34, 34);
}
.card-bottom-text {
  font-size: 14px;
  text-align: left;
  width: 95%;
  margin-top: 1rem;
  margin-left: 0.5rem;
  
}

.rect1{
  width: 65%;
  height: 3rem;
  border-radius: var(--radi1);
  align-items: center;
  position: relative;
  top: 1rem;
  left: 1rem;
}
.rect2{
  width: 60%;
  height: 2.6rem;
  border-radius: var(--radi1);
  align-items: center;
  position: relative;
  top: 2rem;
  left: 3rem;
}



.square {
  width: 1.8rem;
  height: 1.8rem;
  margin: 0 0.5rem;
  border-radius: var(--radi1);
  transition: transform 0.5s ease;
}

.long {
  width: 58%;
  height: 0.5rem;
  background-color: rgba(128, 128, 128, 0.599);
  border-radius: 5px;
  transition: width 0.5s ease;
}

.box:hover .long{
  width: 65%;
}

.box:hover .square {
  transform: rotate(90deg);
}


.bulb-circle {
  width: 4rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  background-color: rgb(255, 76, 106);
  position: relative;
}

.bulb, .sparkle {
  width: 2rem;
  position: absolute;
  align-self: cente;
  margin: auto;
  
}

.bulb {
  width: 2rem;
  right: 18px;
  transition:  right 0.5s ease;
}
.ref:hover .bulb {
  right: 20px;
}

.chat-cover{
  display: flex;
  width: 10rem;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}



.chat {
  width: 8rem;
  transition: transform 0.5s ease;
}

.ref2:hover .chat{
  transform: rotate(20deg);
}
.sparkle {
  width: 1.5rem;
  right: 12px;
  top: 16px;
  transition:  right 0.5s ease;
}

.ref:hover .sparkle{
  right: 10px;
}




/* SECTION 3 STYLING */

.double-div-cover {
  width: 100%;
 
  padding-bottom: 0;
}
.cover{
  display: flex;
  flex-direction: column;
 
  width: 100%;
  overflow-x: hidden;
  position: relative;
  top: -7rem;

}

.trapez {
  width: 100%;
  padding: 4rem;
  
  background-color: var(--lemon);
  position: relative;
  
  top: 6rem;
  transform: rotate(-1.5deg);
  border-radius: 2rem 2rem 0 0;

  
}

.trapez2 {
  width: 100%;
  padding: 4rem;
  
  background-color: white;
  position: absolute;
  
  top: 5rem;
  transform: rotate(-1.5deg);
  border-radius: 2rem 2rem 0 0;
 
}



.bit-div, .upcoming-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem 1rem;
  border-radius: var(--radi);
}
.bit-div{
  border-radius: 2rem;
  padding-bottom: 10rem;
  color: var(--blue);
  position: relative;
  overflow: hidden;
}

.pips2{
  opacity: 30%;
 
  bottom:  0.5rem;
  left: 2rem;
}
.upcoming-div{
  z-index: 1;
  border-radius: 0 0 2rem 2rem;
  border-left: 1px solid white;
  width: 100%;
 
}


.thinking-skills {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  padding: 1rem;

  
}


.sparkle2 {
  width: 2.5rem;
  position: relative;
  align-self: center;
  margin: 0;
  padding: 0;
  right: -1.2rem;
  bottom: -0.8rem;
  transition: transform 0.5s  ease;
  
}

.skill {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  
  width: 20%;
  font-size: 15px;
  font-weight: 500;
  margin-top: 1rem;
  height: 2rem;
  cursor: pointer;
 
  
  
}

.skill:hover .sparkle2 {
  transform: rotate(10deg);
}
.titles {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 5px;
  width: 40%;
  font-size: 15px;
  font-weight: 500;
  margin-top: 1rem;
 
}

.fa {
  margin: 0 6px;
}
.upcomings {
  display: flex;
  flex-wrap: wrap;
  width: 75%;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  padding: 1rem;
  
  
}
.btn-cover {
  width: 30%;
  margin: 0 auto;
}

.btn-syllabus, .btn-reg {
  border-radius: 50px;

  padding: 1rem 1.5rem;
 
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  
  font-size: 18px;
  margin-top: 2rem;
  z-index: 3;
  
}
.btn-syllabus {
  background-color: transparent;
  border: 2px solid var(--blue);
  transition: border-color 0.5s ease;
  cursor: pointer;
}

.btn-reg {
  background-color: var(--blue);
  border: 2px solid var(--blue);
  color: whitesmoke;
  transition: background-color 0.5s ease, border-color 0.5s ease, color 0.5s ease;
  cursor: pointer;
}



.btn-syllabus:hover {
  border-color: blue;
  color: blue;
  
}

.btn-reg:hover {
  color: var(--blue);
  background-color: transparent;
}


.footer{
  margin-top: 2rem;
}

.footer-container {
  width: 100%;
  
  padding: 2rem 5rem ;
  padding-bottom: 0;
  border-radius: 2rem 2rem 0 0;
  background: linear-gradient(
    #320f5c, #3e1274
  );
}


.footer-card {
  display: flex;
  flex-direction: column;
  border-radius: 2rem;
  width: 40%;
  padding: 2rem;
  background-color: whitesmoke;
  margin: 2rem auto;
  margin-bottom: 0;
}


.f-card-header {
  font-size: 2rem;
  width: 55%;
  text-align: left;
}

.learn {
  display: flex;
  width: auto;
  justify-content: space-between;
  align-items: center;
  margin-top: 0;
}

.footer-text {
  width: 55%;
  text-align: left;
}

.learn-btn {
  padding: 1rem 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: whitesmoke;
  background-color:#6912cd;
  border-radius: 50px;
  cursor: pointer;
  border: 2px solid whitesmoke;
  transition: background-color 0.5s ease, border-color 0.5s ease, color 0.5s ease;
  
}

.learn-btn:hover{
  background-color: transparent;
  border: 2px solid rgb(70, 0, 128);
  color: rgb(70, 0, 128);
}

.events-div  {
  display:  flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  
}

.events-head{
  text-align: left;
  padding: 8px;
  margin-left: 0;
  margin-right: auto;
  font-size: 1.3rem;
  font-weight: 400;

}

.img-cover {
  box-shadow: 0 2px 10px rgba(128, 128, 128, 0.113);
}

.img-cover:hover .event-img{
  transform: scale(1.1);
  transition: transform 0.5s ease;
}



.carousel-container{
  display: flex;
  align-items: center;
  width: 85%;
  margin: 0 auto;
}

.coli {
  display: flex;
  flex-direction: column;
  width: auto;
  margin-top: -5rem;
}

.coli h3 {
  margin-left: 6rem;
  margin-bottom: 1rem;
  font-size: 1.4rem;
  font-weight: 400;
}
.carousel-item{
 
  height: 12rem;
  width: 14rem;
  border-radius: 8px;
  overflow: hidden;

}

.carousel-image {
  width:  14rem;
  transition: transform 0.5s ease-in-out;
  
}

.carousel-item:hover .carousel-image {
  transform: scale(1.1);
}

.events {
  display: none;
}
.footer-bottom {
  width: 100%;
  height: 8rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  bottom: 1rem;
  margin-top: 5rem;

}
.media {
  width: 18%;
  display: flex;
  flex-direction: column;
  align-items: center;
  
}

.f-logo{
  width: 100%;
  height: 3rem;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  right: -1em;

 
}
.logo {
  width: 15rem;
  margin: 0 auto;
  
}

.media-icons {
  display: flex;
  width: 90%;
  align-items: center;
  justify-content: space-between;
  margin: 0.5rem auto;
  margin-left: 0;
}
.m-icon {
  padding: 0.95rem 1rem;
  background-color: white;
  color: navy;
  font-size: 1rem;
  border-radius: 50px;
  align-self: center;
  justify-content: center;
  
}
.l-icon {
  padding: 0.9rem 0.7rem;
  background-color: white;
  color: navy;
  font-size: 1rem;
  border-radius: 50px;
  align-self: center;
  justify-content: center;
  
}
.fa-linkedin {
  padding: 1px 2px;
  background-color: navy;
  color: white;
  border-radius: 2px;
}

.g-text{
  color: rgb(207, 207, 207);
}