@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Caveat&family=Roboto:wght@300;400&display=swap');


@media only screen and (max-width: 425px) {


    .container{
        display: flex;
        width: 99%;
        margin: 0 auto;
       
      }

      .navbar {
        display: flex;
        padding:  1rem 2rem;
        align-items: center;
        justify-content: center;
        
      }

      .nav-menu {
        width: 60%;
        
      }
      .logo{
        width: 6rem;
      }

    .purp-b {
        position: relative;
       
        height: 32rem;
        width: 98%;
        margin-left: auto;
        margin-right: auto;
       
      }
      

      .l-gap {
        margin-left: -5rem;
      }

      .hero {
        position: absolute;
        margin: 0 auto;
        color: whitesmoke;
        position:  relative;
      
        height:  25rem;
        left: 0;
        top: 6rem;
        z-index: 1;
        width: 98%;
        margin:  0 auto;
        align-items: center;
        justify-content: center;
      
      }
      
      .asterisk {
        width: 1rem;
        margin: 0 8px;
      }
      
      
      
      .bg-image-div {
        position: absolute;
        top: 0;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        height: 32rem;
        border-radius: 2rem;
       
        overflow: hidden;
        z-index: 0;
        
      }
      .header-bg{
        width: 70rem;
        position: relative;
        top: -1rem;
        left: -8rem;
        
      }
      
      
      
      .header1 {
        font-size: 1.7rem;
        margin: 0;
        padding: 0;
        font-weight: 600;
      }
   
      
      .h-text {
        font-size: 14px;
        width: 90%;
       
        
        margin-top: 1rem;
      }
      .h-text2 {
        font-size: 14px;
        width: 45%;
       
      }
      .btn-started {
       
        padding: 1rem ;
        width: 8rem;
        
        font-size: 16px;
        margin-top: 2rem;
        
        border: 2px solid whitesmoke;
        
        
      }
     

      
      .section-header {
        display: flex;
        flex-direction: column;
        padding: 1.5rem;
        margin-top: 0.5rem;
        width: 86%;
        text-align: left;
        
      
      }
      
      .stily-text {
        font-size: 15px;
        color: purple;
        font-family: 'Caveat', cursive;
      }
      
      .header2 {
        font-size: 1.5rem;
        margin: 0;
        padding: 0;
        width: 90%;
        font-weight: 600;
      }
      
      
      .box-div {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width:  100%;
        margin-left: auto;
        margin-right: auto;
      }
      .box-cover{
        display: flex;
        flex-direction: column;
        background-color: whitesmoke;
        padding: 1.5rem 0.98rem; 
        margin-top: 1rem;
        border-radius: var(--radi);
        width: 100%;
      }
      
      .box {
        width: 95%;
        margin: 0 auto;
        border-radius: var(--radi);
        padding: 1rem 0;
        margin-top: 1rem;
        height: 10rem;
        padding: 0.5rem;
        
      }
      
      .box-2 {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      
      .card-header{
        font-size: 1rem;
        text-align: left;
        margin-left: 0.5rem;
        font-weight: 600;
      }
      
      .card-text {
        font-size: 14px;
        text-align: left;
      }
      .card-bottom-text {
        font-size: 15px;
        text-align: left;
        width: 98%;
        margin-top: 1rem;
        margin-left: 0.5rem;
        
      }
      
      .rect1{
        width: 65%;
        height: 3rem;
        border-radius: var(--radi1);
        align-items: center;
        position: relative;
        top: 1rem;
        left: 1rem;
      }
      .rect2{
        width: 60%;
        height: 2.6rem;
        border-radius: var(--radi1);
        align-items: center;
        position: relative;
        top: 2rem;
        left: 3rem;
      }
      
      .square {
        width: 1.8rem;
        height: 1.8rem;
        margin: 0 0.5rem;
        border-radius: var(--radi1);
      }
      
      .long {
        width: 58%;
        height: 0.5rem;
        background-color: rgba(128, 128, 128, 0.599);
        border-radius: 5px;
        transition: width 0.5s ease;
      }
      
      .long:hover {
        width: 65%;
      }
      
      
      .bulb-circle {
        width: 4rem;
        height: 4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50px;
        background-color: rgb(255, 76, 106);
        position: relative;
      }
      
      .bulb, .sparkle {
        width: 2rem;
        position: absolute;
        align-self: cente;
        margin: auto;
      
      }
      
      .bulb {
        width: 2rem;
        right: 18px;
      }
      
      .chat-cover{
        display: flex;
        width: 10rem;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
      }
      
      .chat {
        width: 8rem;
        
      }
      
      .sparkle {
        width: 1.5rem;
        right: 12px;
        top: 16px;
      }
      
      









      .cover{
        width: 100%;
        top: -8rem;
      }
      .bit-div{
        border-radius: 4rem;
        padding-bottom: 6.5rem;
        width: 98%;
        margin-left: auto;
        margin-right: auto;
      
      }
      .upcoming-div{
        z-index: 1;
        border-radius: 0 0 4rem 4rem;
        border-right: 0.1px solid white;
        border-left: 0 ;
        width: 100%;
        padding-top: 1rem;
        border-radius: 6px 0 4rem 4rem;
        
      }

      .trapez {
        width: 100%;
        padding: 4rem 5rem 4rem 4rem;
        
        background-color: var(--lemon);
        position: relative;
        
        top: 5rem;
        transform: rotate(-6deg);
        border-radius: 3rem 3.98rem 0 0;
        
        
      }
      .trapez2 {
        width: 100%;
        padding: 5rem 6rem 4rem 4rem;
        
        background-color: white;
        position: absolute;
        
        top: 4.2rem;
        right: -0.1rem;
        transform: rotate(-6deg);
        border-radius: 2rem 3.5rem 0 0;
       
        
      }


      .thinking-skills {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        width: auto;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        margin-top: 1rem;
        padding: 1rem;
        
      }
      
      
      .sparkle2 {
        width: 2.5rem;
        position: relative;
        align-self: center;
        margin: 0;
        padding: 0;
        right: -1.2rem;
        bottom: -0.8rem;
        
      }
      
      .skill {
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: left;
        
        width: 100%;
        font-size: 15px;
        font-weight: 500;
        margin-top: 1rem;
        height: 2rem;
      
        
        
      }

      .h-text2 {
        font-size: 14px;
        width: 98%;
        margin: 0 auto;
        position: relative;
        text-align: center;
        margin-top: 1rem;
      }
      
      .titles {
        display: flex;
      
        align-items: center;
        text-align: center;
        justify-content: center;
        padding: 0;
        width: 100%;
        font-size: 14px;
        font-weight: 500;
        margin-top: 1rem;
      }
      
      
      .fa {
        margin: 0 6px;
      }
      .upcomings {
        display: flex;
        flex-wrap: wrap;
        width: 55%;
        align-items: center;
        justify-content: center;
        
        margin-top: 1rem;
        padding: 1rem;
        
      }
      .btn-cover {
        width: 80%;
        margin: 0 auto;
        
        
      }
      
      .btn-syllabus, .btn-reg {
        border-radius: 50px;
      
        padding: 1rem 1.5rem;
       
        display: flex;
        margin: 0 auto;
        align-items: center;
        justify-content: center;
        
        font-size: 14px;
        margin-top: 2rem;
        cursor: pointer;
        transition: background-color  0.5s ease;
      }
    

      
      





      .events {
        display: block;
      }
      .events-div  {
        display:  flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
        margin-top: -5rem;
  
      }
      
      .events-head{
        text-align: left;
        padding: 8px;
        margin-left: 1rem;
        margin-right: auto;
      
      }
      
      .events-text{
        padding: 8px 0;
        text-align: left;
        margin-left: 0;
        margin-right: auto;
      }
      .events-cover{
        display: flex;
        flex-direction: column;
        width: 100%;
        overflow: hidden;
      }
      
      
      .img-and-text{
        width: 80%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0.5rem auto;
      }
      
      .img-cover {
        width: 100%;
        height: 12rem;
        overflow: hidden;
        border-radius: 8px;
        box-shadow: 0 2px 10px rgba(128, 128, 128, 0.113);
      }
      
      .event-img {
        width: 100%;
      }

      .coli {
        display: none;
      }




      .footer-container{
        padding: 2rem 0;
        
        border-radius: 4rem  4rem 0 0;
        width: 100%;
        margin: 0 auto;
      }
      
      .footer-card {
        display: flex;
        flex-direction: column;
        border-radius: 3rem;
        width: 95%;
        padding: 1.5rem;
       
        background-color: whitesmoke;
        margin: 2rem auto;
        margin-bottom: 1rem;
      }
      
      
      .f-card-header {
        font-size: 2.5rem;
        width: 90%;
        text-align: center;
        margin: 0.4rem auto;
        padding: 5px;
      }
      
      .learn {
        display: flex;
        flex-direction: column;
        width: auto;
        justify-content: space-between;
        align-items: center;
        margin-top: 1rem;
      }
      
      .footer-text {
        width: 80%;
        text-align: center;
      }
      
      .learn-btn {
        padding: 1.5rem 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        color: whitesmoke;
        
        border-radius: 50px;
        cursor: pointer;
        border: 2px solid whitesmoke;
        transition: background-color 0.5s ease, border-color 0.5s ease, color 0.5s ease;
        margin-top: 2rem;
      }
      
   
      .footer-bottom {
        width: 80%;
        height: 12rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        position: relative;
        bottom: 0;
        margin: 0 auto;
        margin-top: 6rem;

      }
      .media {
        width: 60%;
        display: flex;
        flex-direction: column;
        align-items: center;
        
      }
      
      .f-logo{
        
        height: 2.5rem;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        
        
      }
      .logo {
        width: 10rem;
        margin: 0 auto;
      }
      
      .media-icons {
        display: flex;
        width: 90%;
        align-items: center;
        justify-content: space-between;
        margin: 0.5rem auto;
      }
      .m-icon {
        padding: 0.6rem 0.6rem;
        background-color: whitesmoke;
        color: navy;
        font-size: 1rem;
        border-radius: 50px;
        align-self: center;
        justify-content: center;
        
      }
      .l-icon {
        padding: 0.6rem 0.3rem;
        background-color: whitesmoke;
        color: navy;
        font-size: 1rem;
        border-radius: 50px;
        align-self: center;
        justify-content: center;
        
      }
      .fa-linkedin {
        padding: 1px 2px;
        background-color: navy;
        color: white;
        border-radius: 2px;
      }
      
      .g-text{
        color: whitesmoke;
      }




}






@media only screen and(max-width: 720){


  
}






